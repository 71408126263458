<template>
  <div class="home">
	<Navbar />
	<h3 class='bheading'>Request Access to this ResorsIT Site.</h3>
	<p class='inst'>This site is {{ setup.site.description }} <br /> It is run by {{ setup.site.owner }}</p>
	<p class='inst'>
		 {{ setup.inst.firstp }}
	</p>
	<p class='inst'>
		 {{ setup.inst.twop }}
	</p>
	<div class='form'>
		<div class="rjdiv" v-if="rstatus.state === 'rejected'">
			<h2 class="rjhead">You Request could not be processed</h2>
			<ul v-for="m in rstatus.message" :key="m">
				<li class='rjmsg'>{{ m }}</li>
			</ul>
		</div>
		<div class="accepted" v-if="rstatus.state === 'accecpted'">
			<h2 class="goodhead">{{ rstatus.head }}</h2>
			<p class="goodmsg" >{{ rstatus.para }}</p>
		</div>
		<div v-if="rstatus.state !== 'accecpted'">
		<h3 class='flabel'>User Id. *</h3>
		<InputText id="userid" v-model="ureq.userid" />
		<p class='fhint'>
		Your user id must start with a letter and must contain only lower case letters, numbers, and the dash.
		</p>
		<h3 class='flabel'>Email Address *</h3>
		<InputText id="email" v-model="ureq.email" />
		<p class='fhint'>
		This email will be used to verify your identity and to allow you to re-set your password if you forget.
		You will have to re-verify this email on a regular basis.
		</p>
		<h3 class='flabel'>Display Name *</h3>
		<InputText id="dname" v-model="ureq.dname" />
		<p class='fhint'>
		The name to be displayed for you.
		</p>
		<h3 class='flabel'>Salutation</h3>
		<InputText id="salutation" v-model="ureq.salutation" />
		<p class='fhint'>
		Your salutation, for example: Mr. Ms. Dr. etc.
		</p>
		<h3 class='flabel'>First Name</h3>
		<InputText id="fname" v-model="ureq.fname" />
		<p class='fhint'>
		Your first or given name.
		</p>
		<h3 class='flabel'>Middle Name</h3>
		<InputText id="mname" v-model="ureq.mname" />
		<p class='fhint'>
		Your middle name or initial. Leave blank if you have no middle name.
		</p>
		<h3 class='flabel'>Last Name</h3>
		<InputText id="lname" v-model="ureq.lname" />
		<p class='fhint'>
		Your last, family, or sir name.
		</p>
		<h3 class='flabel'>Generation</h3>
		<InputText id="gen" v-model="ureq.gen" />
		<p class='fhint'>
		Your generation name modifier, for example: Sr. Jr. III. Leave blank for None.
		</p>
		<h3 class='flabel'>Notes</h3>
		<Textarea id="notes" v-model="ureq.notes" autoResize rows="8" cols="60" />
		<p class='fhint'>
		Any notes on your request to be passed on to the reviewers.
		</p>
		<Button class="savebutton" label='Submit' icon='pi pi-cloud-upload' @click='dosubmit' />
		<p class='fhint'>
		* Required Field.
		</p>
		</div>

	</div>

    </div>
</template>


<script>
import { ref } from 'vue'
// @ is an alias to /src
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea';
import Button from 'primevue/button'
import Navbar from '@/components/Navbar.vue'
import getSetup from '@/utility/getSetup'
import putRequest from '@/utility/putRequest'

export default {
	components: { 
		InputText,
		Textarea,
		Button,
		Navbar
	},
	setup() {

	const mode = ref('UNKNOWN');

	const ureq = ref({
		'userid': '',
		'email': '',
		'dname': '',
		'fname': '',
		'lname': '',
		'gen': '',
		'notes': '',
		'salutation': ''
		});

	const { setup, error, loadsetup } = getSetup()
	const { rstatus, putrequest } = putRequest()

	mode.value = process.env.NODE_ENV
	loadsetup()

	const dosubmit = () => {
		console.log("DOSAVE " + JSON.stringify(ureq.value, null, 4))
		putrequest(ureq)
		console.log("RSTATUS " + JSON.stringify(rstatus.value, null, 4))
		
	}

	return { setup, error, mode, ureq, rstatus, dosubmit }
	}
}

</script>
<style lang="scss" scoped>
.bheading {
	color: #c413c4;
	font-weight: bold;
	font-size: xx-large;

}
.inst {
	color: #486d0c;
	font-size: large;
	max-width: 50em;
}
.form {
	padding: 15px;
	background: white;
	border-radius: 10px;
	max-width: 90em;
}
.flabel {
	margin-bottom: 0px;
}
.fhint {
	margin-top: 0px;
}
</style>
